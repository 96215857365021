html {
  position: relative;
}

body {
  background-color: #343a40 !important;
}

a:link, a:visited, a:hover, a:active {
  color: white;
}

a:link, a:visited, a:active {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1 {
  font-size: x-large !important;
}